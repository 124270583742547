import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores";
import homeRoutes from "./home.route";
import authenticateRoute from "./authenticate.route";
import resourceRoute from "./resource.route";
import privilegeRoute from "./privilege.route";
import postMetaRoute from "./post_meta.route";
import serviceRoute from "./service.route";
import contactRoute from "./contact.route";
import socialRoute from "./social.route";
import userRoute from "./user.route";
import emailTemplateRoute from "./email_template.route";
import emailRecipientRoute from "./email_recipient.route";
import userForgotPasswordRoute from "./user_forgot_password.route";
import brandRoute from "./brand.route";
import postRoute from "./post.route";
import bannerRoute from "./banner.route";
import emailSenderRoute from "./email_sender.route";
import optionRoute from "./option.route";
import roleRoute from "./role.route";
import profileRoute from "./profile.route";

const routes = [
  { ...homeRoutes },
  { ...authenticateRoute },
  { ...resourceRoute },
  { ...privilegeRoute },
  { ...postMetaRoute },
  { ...serviceRoute },
  { ...contactRoute },
  { ...socialRoute },
  { ...userRoute },
  { ...emailTemplateRoute },
  { ...emailRecipientRoute },
  { ...userForgotPasswordRoute },
  { ...brandRoute },
  { ...postRoute },
  { ...bannerRoute },
  { ...emailSenderRoute },
  { ...optionRoute },
  { ...roleRoute },
  { ...profileRoute },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const publicPages = [
    "/login",
    "/register",
    "/forgot-password",
    "/reset-password",
    "/active",
  ];
  const authRequired = !publicPages.includes(to.path);
  const authStore = useAuthStore();
  if (authRequired && !authStore.user) {
    authStore.returnUrl = to.fullPath;
    return "/login";
  }
});

export default router;
