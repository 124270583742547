<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">Contact</h6>
        <div class="d-inline-flex ms-auto">
          <router-link to="/contacts" class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Name:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Name"
                v-model="contact.name"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Phone:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Phone"
                v-model="contact.phone"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Email:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Email"
                v-model="contact.email"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Subject:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Subject"
                v-model="contact.subject"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Message:</label>
            <div class="col-lg-9">
              <textarea
                rows="3"
                cols="3"
                class="form-control"
                maxlength="511"
                placeholder="Message"
                v-model="contact.message"
              ></textarea>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Status"
                class="form-control status"
              >
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-end" v-if="contact.id || !id">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              Save <i class="ph-paper-plane-tilt ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
</template>

<script setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, useContactStore } from "@/stores";

import { alert, select } from "@/helpers";

document.title = "Contact";

const route = useRoute();
const id = route.params.id;
const authStore = useAuthStore();
const contactStore = useContactStore();
const { contact, statuses } = storeToRefs(contactStore);
contact.value = {};

const result = authStore.getPermission("contact");
if ((result?.update !== true && id) || (result?.create !== true && !id)) {
  router.push("/login");
}

async function init() {
  select.init("status", (item) => {
    contact.value.status = parseInt(item.id);
  });
  if (id) {
    await contactStore.getById(id);
    select.setValue("status", contact.value.status);
  }
}

async function onSubmit() {
  if (!contact.value.name) {
    alert("Please enter Name");
    return;
  }
  if (!contact.value.phone) {
    alert("Please enter Phone");
    return;
  }
  if (!contact.value.email) {
    alert("Please enter Email");
    return;
  }
  // if (!contact.value.subject) {
  //   alert("Please enter Subject");
  //   return;
  // }
  // if (!contact.value.message) {
  //   alert("Please enter Message");
  //   return;
  // }
  // if (!contact.value.status) {
  //   alert("Please enter Status");
  //   return;
  // }

  const result = await contactStore.submit();
  if (result) {
    // router.push("/contacts");
    router.back();
  }
}

onMounted(() => init());
</script>
