/* eslint-disable */
import { dateFormat } from "@/helpers";

export const datepicker = {
  init: init(),
  setValue: setValue(),
};

function init() {
  return (name, onApply, min = 100, max = 3) => {
    $("." + name)
      .daterangepicker({
        defaultDate: null,
        parentEl: ".content-inner",
        singleDatePicker: true,
        showDropdowns: true,
        autoApply: true,
        autoUpdateInput: false,
        minDate: moment().subtract(min, "years"),
        maxDate: moment().add(max, "years"),
        locale: {
          format: "DD-MM-YYYY",
        },
      })
      .on("apply.daterangepicker", (ev, picker) => {
        $("." + name).val(dateFormat.formatDateString(picker.startDate));
        onApply(
          dateFormat.formatDateString(picker.startDate, "YYYY-MM-DDTHH:mm:ssZ")
        );
      });
    $("." + name).on("keyup", function (e) {
      if (e.key === "Enter") {
        var date = $("." + name).data("daterangepicker").startDate;
        $("." + name).val(dateFormat.formatDateString(date));
        onApply(dateFormat.formatDateString(date, "YYYY-MM-DDTHH:mm:ssZ"));
      }
    });
  };
}

function setValue() {
  return (name, value, format = "DD-MM-YYYY") => {
    if (value) {
      const date = dateFormat.formatDateString(value, format);
      $("." + name).val(date);
      $("." + name)
        .data("daterangepicker")
        .setStartDate(date);
      $("." + name)
        .data("daterangepicker")
        .setEndDate(date);
    }
  };
}
