import { useAuthStore } from "@/stores/auth.store";
import { API_URL } from "@/const";
import router from "@/router";

export const fetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
};

function request(method) {
  return async (url, body) => {
    console.log(`${method}: ${API_URL}${url}`);

    const requestOptions = {
      method,
      headers: authHeader(),
    };

    if (body) {
      requestOptions.headers["content-type"] = "application/json";
      requestOptions.body = JSON.stringify(body);

      console.log(body);
    }

    const response = await fetch(`${API_URL}${url}`, requestOptions);

    return handleResponse(response);
  };
}

function authHeader() {
  const { user } = useAuthStore();
  const isLoggedIn = !!user?.token;

  if (isLoggedIn) {
    return { Authorization: `Bearer ${user.token}` };
  }

  return {};
}

async function handleResponse(response) {
  const isJson = response.headers
    ?.get("content-type")
    ?.includes("application/json");

  const json = isJson ? await response.json() : null;

  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      router.push("/login");
    }

    // get error message from body or default response status
    const error = (json && `${json.message} (${json.log})`) || response.status;
    return Promise.reject(error);
  }

  return json;
}
