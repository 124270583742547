<template>
  <!-- Content area -->
  <div class="content">
    <!-- table borders -->
    <div class="card" v-if="permission?.view">
      <div class="card-header d-lg-flex">
        <div class="d-flex">
          <h4 class="mb-0">Post</h4>
          <a
            href="#page_header"
            class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto"
            data-bs-toggle="collapse"
          >
            <i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
          </a>
        </div>

        <div class="collapse d-lg-block my-lg-auto ms-lg-auto" id="page_header">
          <div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
            <div
              class="form-control-feedback form-control-feedback-end flex-fill"
            >
              <input
                type="text"
                class="form-control"
                placeholder="Search"
                v-model="option.keyword"
                v-on:keyup.esc="onEsc"
                v-on:keyup.enter="onEnter"
              />
              <div class="form-control-feedback-icon">
                <i class="ph-magnifying-glass opacity-50"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <!-- <th>
                <a href="#" @click="orderBy('author')">
                  Author
                  <span
                    v-if="
                      option.orderBy === 'author' && option.sortBy === 'asc'
                    "
                    >&darr;</span
                  >
                  <span
                    v-if="
                      option.orderBy === 'author' && option.sortBy == 'desc'
                    "
                    >&uarr;</span
                  >
                </a>
              </th> -->
              <th>
                <a href="#" @click="orderBy('name')">
                  Name
                  <span
                    v-if="option.orderBy === 'name' && option.sortBy === 'asc'"
                    >&darr;</span
                  >
                  <span
                    v-if="option.orderBy === 'name' && option.sortBy == 'desc'"
                    >&uarr;</span
                  >
                </a>
              </th>
              <th>
                <a href="#" @click="orderBy('title')">
                  Title
                  <span
                    v-if="option.orderBy === 'title' && option.sortBy === 'asc'"
                    >&darr;</span
                  >
                  <span
                    v-if="option.orderBy === 'title' && option.sortBy == 'desc'"
                    >&uarr;</span
                  >
                </a>
              </th>
              <!-- <th>
                <a href="#" @click="orderBy('date')">
                  Date
                  <span
                    v-if="option.orderBy === 'date' && option.sortBy === 'asc'"
                    >&darr;</span
                  >
                  <span
                    v-if="option.orderBy === 'date' && option.sortBy == 'desc'"
                    >&uarr;</span
                  >
                </a>
              </th>
              <th>
                <a href="#" @click="orderBy('type')">
                  Type
                  <span
                    v-if="option.orderBy === 'type' && option.sortBy === 'asc'"
                    >&darr;</span
                  >
                  <span
                    v-if="option.orderBy === 'type' && option.sortBy == 'desc'"
                    >&uarr;</span
                  >
                </a>
              </th> -->
              <th>
                <a href="#" @click="orderBy('parent')">
                  Parent
                  <span
                    v-if="
                      option.orderBy === 'parent' && option.sortBy === 'asc'
                    "
                    >&darr;</span
                  >
                  <span
                    v-if="
                      option.orderBy === 'parent' && option.sortBy == 'desc'
                    "
                    >&uarr;</span
                  >
                </a>
              </th>
              <th>
                <a href="#" @click="orderBy('menu_order')">
                  Menu Order
                  <span
                    v-if="
                      option.orderBy === 'menu_order' && option.sortBy === 'asc'
                    "
                    >&darr;</span
                  >
                  <span
                    v-if="
                      option.orderBy === 'menu_order' && option.sortBy == 'desc'
                    "
                    >&uarr;</span
                  >
                </a>
              </th>
              <!-- <th>Photo</th> -->
              <th class="text-center w125">
                <router-link
                  to="/posts/add"
                  class="text-primary"
                  v-if="permission?.create"
                >
                  <i
                    class="ph-plus-circle"
                    data-bs-popup="tooltip"
                    title="Add"
                  ></i>
                </router-link>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="posts.length">
              <tr v-for="post in posts" :key="post.id">
                <!-- <td>{{ post.author?.name }}</td> -->
                <td>{{ post.name }}</td>
                <td>{{ post.title }}</td>
                <!-- <td>
                  {{
                    dateFormat.formatDateString(
                      post.date,
                      "DD-MM-YYYY HH:mm:ss"
                    )
                  }}
                </td>
                <td>{{ post.type }}</td> -->
                <td>{{ post.parent?.title }}</td>
                <td>{{ post.menu_order }}</td>
                <!-- <td>
                  <a
                    v-if="post.photo"
                    :href="API_URL + post.photo.path"
                    target="_blank"
                  >
                    <img
                      :src="API_URL + post.photo.path"
                      :alt="post.photo.name"
                      width="64"
                    />
                  </a>
                </td> -->
                <td class="text-center">
                  <router-link
                    :to="`/posts/${post.id}`"
                    class="text-info me-1"
                    v-if="permission?.view"
                  >
                    <i class="ph-eye"></i>
                  </router-link>
                  <router-link
                    :to="`/posts/edit/${post.id}`"
                    class="text-indigo me-1"
                    v-if="permission?.update"
                  >
                    <i class="ph-pen"></i>
                  </router-link>
                  <a
                    href="#"
                    class="text-pink"
                    @click="showConfirmDialog(post)"
                    v-if="permission?.delete"
                    ><i class="ph-trash"></i
                  ></a>
                </td>
              </tr>
            </template>
            <tr v-if="posts.loading">
              <td colspan="9" class="text-center">
                <div
                  class="spinner-border text-primary me-2"
                  role="status"
                ></div>
                <div
                  class="spinner-border text-danger me-2"
                  role="status"
                ></div>
                <div class="spinner-border text-success" role="status"></div>
              </td>
            </tr>
            <tr v-if="posts.error">
              <td colspan="9">
                <div class="text-danger">Error: {{ posts.error }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- /table borders -->

    <template v-if="paging.pages">
      <ul class="pagination justify-content-end">
        <li class="page-item" v-bind:class="paging.older ? '' : 'disabled'">
          <a href="#" class="page-link" @click="changePage(paging.older)"
            >&larr;</a
          >
        </li>
        <li class="page-item" v-for="page in paging.pages" :key="page">
          <a
            href="#"
            class="page-link"
            v-bind:class="page == paging.page ? 'active' : ''"
            @click="changePage(page)"
          >
            {{ page }}</a
          >
        </li>
        <li class="page-item" v-bind:class="paging.newer ? '' : 'disabled'">
          <a href="#" class="page-link" @click="changePage(paging.newer)"
            >&rarr;</a
          >
        </li>
      </ul>
    </template>
  </div>
  <!-- /content area -->
  <ConfirmDialog ref="dialog" :callback="onDelete" />
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useAuthStore, usePostStore } from "@/stores";
// import { dateFormat, alert } from "@/helpers";
// import { API_URL } from "@/const";
import router from "@/router";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

document.title = "Post";

const authStore = useAuthStore();
const postStore = usePostStore();
const permission = ref();
const dialog = ref();
const { posts, paging, option } = storeToRefs(postStore);
let id = "";

const result = authStore.getPermission("post");
if (result?.view) {
  permission.value = result;
} else {
  router.push("/login");
}

postStore.getPosts();

function orderBy(c) {
  option.value.orderBy = c;
  if (option.value.sortBy == "asc") {
    option.value.sortBy = "desc";
  } else {
    option.value.sortBy = "asc";
  }
  postStore.getPosts();
}

function changePage(p) {
  option.value.page = p;
  postStore.getPosts();
}

function onEnter() {
  postStore.getPosts();
}

function onEsc() {
  option.value.keyword = null;
  postStore.getPosts();
}

function showConfirmDialog(post) {
  id = post.id;
  dialog.value.show(`Nhấn OK để xoá.`);
}

async function onDelete() {
  const result = await postStore.delete(id);
  if (result) {
    alert("Xoá thành công", "bg-teal");
    postStore.getPosts();
  }
}
</script>
