<!-- eslint-disable prettier/prettier -->
<template>
  <!-- Login form -->
  <form
    id="needs-validation"
    class="login-form"
    novalidate
    v-on:submit.prevent=""
  >
    <div class="card mb-0">
      <div class="card-body">
        <div class="text-center mb-3">
          <div
            class="d-inline-flex align-items-center justify-content-center mb-4 mt-2"
          >
            <img src="/assets/images/logo.svg" class="h-48px" alt="" />
          </div>
          <h5 class="mb-0">Active Account</h5>
          <span class="d-block text-muted">{{ message }}</span>
        </div>

        <div class="mb-3">
          <router-link to="/login" class="btn btn-primary w-100"
            >Sign in</router-link
          >
        </div>
      </div>
    </div>
  </form>
  <!-- /login form -->
</template>

<script setup>
import { useAuthStore } from "@/stores";
import { useRoute } from "vue-router";
import { ref } from "vue";
import router from "@/router";

document.title = "Active Account";

const route = useRoute();
const authStore = useAuthStore();
authStore.logout();

const message = ref("");

const token = route.query.token;
authStore.activeUser(token).then((result) => {
  if (result) {
    message.value = "Kích hoạt tài khoản thành công";
  } else {
    router.push("/login");
  }
});
</script>
