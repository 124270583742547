<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">Post Meta</h6>
        <div class="d-inline-flex ms-auto">
          <router-link to="/post-metas" class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body disable">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Id:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="postMeta.id"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Created At:</label>
            <div class="col-lg-9">
              <input type="text" class="form-control created_at" disabled />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Updated At:</label>
            <div class="col-lg-9">
              <input type="text" class="form-control updated_at" disabled />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Post:</label>
            <div class="col-lg-9">
              <select class="form-control post" data-placeholder="‎" disabled>
                <option></option>
                <option v-if="postMeta.post" :value="postMeta.post?.id">
                  {{ postMeta.post?.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Key:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="postMeta.key"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Value:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="postMeta.value"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Meta Type:</label>
            <div class="col-lg-9">
              <div class="form-check-horizontal">
                <label class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="type"
                    value="1"
                    v-model="postMeta.type"
                    disabled
                  />
                  <span class="form-check-label">Name</span>
                </label>
                <label class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="type"
                    value="0"
                    v-model="postMeta.type"
                    disabled
                  />
                  <span class="form-check-label">Property</span>
                </label>
              </div>
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select class="form-control status" data-placeholder="‎" disabled>
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-end" v-if="postMeta.id">
            <button
              type="submit"
              class="btn btn-pink mx-2"
              @click="showConfirmDialog"
            >
              Delete <i class="ph-trash ms-2"></i>
            </button>
            <router-link :to="`/post-metas/edit/${id}`" class="btn btn-indigo"
              >Edit <i class="ph-pen ms-2"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
  <ConfirmDialog ref="dialog" :callback="onDelete" />
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, usePostMetaStore } from "@/stores";
import { alert, datepicker, select, selectSearch } from "@/helpers";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

document.title = "Post Meta";

const authStore = useAuthStore();
const postMetaStore = usePostMetaStore();
const route = useRoute();
const id = route.params.id;
const { postMeta, statuses } = storeToRefs(postMetaStore);
postMeta.value = {};
const dialog = ref();

const result = authStore.getPermission("post_meta");
if (result?.view !== true) {
  router.push("/login");
}

async function init() {
  datepicker.init("created_at");
  datepicker.init("updated_at");
  selectSearch.init("post", "posts");
  select.init("status");
  if (id) {
    await postMetaStore.getById(id);
    datepicker.setValue(
      "created_at",
      postMeta.value.created_at,
      "DD-MM-YYYY HH:mm:ss",
    );
    datepicker.setValue(
      "updated_at",
      postMeta.value.updated_at,
      "DD-MM-YYYY HH:mm:ss",
    );
    selectSearch.setValue("post", postMeta.value.post?.id);
    select.setValue("status", postMeta.value.status);
  }
}

function showConfirmDialog() {
  dialog.value.show("Click OK to delete.");
}
async function onDelete() {
  const result = await postMetaStore.delete(id);
  if (result) {
    alert("Delete successfully", "bg-teal");
    router.push("/post-metas");
  }
}

onMounted(() => init());
</script>
