<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">Banner</h6>
        <div class="d-inline-flex ms-auto">
          <router-link to="/banners" class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Name:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Name"
                v-model="banner.name"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Description:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Description"
                v-model="banner.description"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Photo:</label>
            <div class="col-lg-9">
              <input
                type="file"
                class="form-control"
                v-on:change="onChangePhoto($event.target.files[0], 'photo')"
              />
              <div class="form-text text-muted" v-if="!banner.photo">
                Accepted formats: png, jpg. Max file size 5Mb
              </div>
              <div v-if="banner.photo">
                <a :href="API_URL + banner.photo.path" target="_blank">
                  <img
                    :src="API_URL + banner.photo.path"
                    :alt="banner.photo.name"
                    :title="banner.photo.name"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Status"
                class="form-control status"
              >
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-end" v-if="banner.id || !id">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              Save <i class="ph-paper-plane-tilt ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
</template>

<script setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, useBannerStore } from "@/stores";

import { select, upload } from "@/helpers";
import { API_URL } from "@/const";

document.title = "Banner";

const route = useRoute();
const id = route.params.id;
const authStore = useAuthStore();
const bannerStore = useBannerStore();
const { banner, statuses } = storeToRefs(bannerStore);
banner.value = {};

const result = authStore.getPermission("banner");
if ((result?.update !== true && id) || (result?.create !== true && !id)) {
  router.push("/login");
}

async function init() {
  select.init("status", (item) => {
    banner.value.status = parseInt(item.id);
  });
  if (id) {
    await bannerStore.getById(id);
    select.setValue("status", banner.value.status);
  }
}
function onChangePhoto(file, type) {
  upload(file, type, (data) => {
    banner.value.photo = data;
  });
}

async function onSubmit() {
  // if (!banner.value.name) {
  //   alert("Please enter Name");
  //   return;
  // }
  // if (!banner.value.description) {
  //   alert("Please enter Description");
  //   return;
  // }
  // if (!banner.value.photo) {
  //   alert("Please enter Photo");
  //   return;
  // }
  // if (!banner.value.status) {
  //   alert("Please enter Status");
  //   return;
  // }

  const result = await bannerStore.submit();
  if (result) {
    // router.push("/banners");
    router.back();
  }
}

onMounted(() => init());
</script>
