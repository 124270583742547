import { useAuthStore } from "@/stores/auth.store";
import { API_URL } from "@/const";
import axios from "axios";

export class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return new Promise((resolve, reject) => {
      this._request(resolve, reject);
    });
  }

  abort() {
    console.log("Abort upload.");
  }

  async _request(resolve, reject) {
    const { user } = useAuthStore();

    let file = await this.loader.file;
    let formData = new FormData();
    formData.append("file", file);
    formData.append("folder", "ckeditor");
    axios({
      method: "POST",
      url: `${API_URL}api/upload`,
      headers: {
        Authorization: `Bearer ${user?.token}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((response) => {
        const url = `${API_URL}/${response.data.data.path}`;
        resolve({
          default: url,
        });
      })
      .catch((error) => reject(error));
  }
}
