<!-- eslint-disable prettier/prettier -->
<template>
  <!-- Login form -->
  <form
    id="needs-validation"
    class="login-form"
    novalidate
    v-on:submit.prevent=""
  >
    <div class="card mb-0">
      <div class="card-body">
        <div class="text-center mb-3">
          <div
            class="d-inline-flex align-items-center justify-content-center mb-4 mt-2"
          >
            <img src="/assets/images/logo.svg" class="h-48px" alt="" />
          </div>
          <h5 class="mb-0">Login to your account</h5>
          <span class="d-block text-muted">Enter your credentials below</span>
        </div>

        <div class="mb-3">
          <label class="form-label">Email</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input
              type="email"
              class="form-control"
              placeholder="hieutao@live.com"
              v-model="user.email"
              autocomplete="username"
              required
            />
            <div class="form-control-feedback-icon">
              <i class="ph-at text-muted"></i>
            </div>
            <div class="invalid-feedback">Invalid email</div>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label">Password</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input
              type="password"
              class="form-control"
              placeholder="•••••••••••"
              v-model="user.password"
              autocomplete="current-password"
              required
            />
            <div class="form-control-feedback-icon">
              <i class="ph-lock text-muted"></i>
            </div>
            <div class="invalid-feedback">Invalid password</div>
          </div>
        </div>

        <!-- <div class="d-flex align-items-center mb-3">
          <label class="form-check">
            <input
              type="checkbox"
              name="remember"
              class="form-check-input"
              checked
            />
            <span class="form-check-label">Remember</span>
          </label>

          <router-link to="/forgot-password" class="ms-auto"
            >Forgot password?</router-link
          >
        </div> -->

        <div class="mb-3">
          <button type="submit" class="btn btn-primary w-100">Sign in</button>
        </div>

        <!-- <div class="text-center text-muted content-divider mb-3">
          <span class="px-2">Don't have an account?</span>
        </div>

        <div class="mb-3">
          <router-link to="register" class="btn btn-light w-100"
            >Sign up</router-link
          >
        </div> -->

        <!-- <span class="form-text text-center text-muted"
          >By continuing, you're confirming that you've read our
          <a href="#">Terms &amp; Conditions</a> and
          <a href="#">Cookie Policy</a></span
        > -->
      </div>
    </div>
  </form>
  <!-- /login form -->
</template>

<script setup>
import { reactive, onMounted } from "vue";
import { useAuthStore } from "@/stores";
import { formValidation } from "@/helpers";

document.title = "Sign in";

const user = reactive({
  email: "",
  password: "",
});

const authStore = useAuthStore();
authStore.logout();

onMounted(() =>
  formValidation(async () => {
    const result = await authStore.login(user.email, user.password);
    if (result) {
      await authStore.getPrivileges();
    }
  }),
);
</script>
