<template>
  <!-- Page content -->
  <div class="page-content">
    <Sidebar />

    <!-- Main content -->
    <div class="content-wrapper">
      <!-- Main navbar -->
      <div class="navbar navbar-expand-lg navbar-static shadow">
        <div class="container-fluid">
          <div class="d-flex d-lg-none me-2">
            <button
              type="button"
              class="navbar-toggler sidebar-mobile-main-toggle rounded-pill"
            >
              <i class="ph-list"></i>
            </button>
          </div>

          <div
            class="navbar-collapse flex-lg-1 order-2 order-lg-1 collapse"
          ></div>

          <ul
            class="nav hstack gap-sm-1 flex-row justify-content-end order-1 order-lg-2"
          >
            <li class="nav-item nav-item-dropdown-lg dropdown">
              <a
                href="#"
                class="navbar-nav-link align-items-center rounded-pill p-1"
                data-bs-toggle="dropdown"
              >
                <div
                  class="status-indicator-container"
                  v-if="authStore?.user?.avatar?.path"
                >
                  <img
                    :src="API_URL + authStore?.user?.avatar?.path"
                    class="h-32px rounded-pill"
                    alt=""
                  />
                  <span class="status-indicator bg-success"></span>
                </div>
                <span class="d-none d-lg-inline-block mx-lg-2">{{
                  authStore?.user?.name
                }}</span>
              </a>

              <div class="dropdown-menu dropdown-menu-end">
                <router-link to="/profile" class="dropdown-item">
                  <i class="ph-user-circle me-2"></i>
                  My Profile
                </router-link>
                <router-link to="/change-password" class="dropdown-item">
                  <i class="ph-lock me-2"></i>
                  Change Password
                </router-link>
                <div class="dropdown-divider"></div>
                <router-link to="/login" class="dropdown-item">
                  <i class="ph-sign-out me-2"></i>
                  Logout
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- /main navbar -->

      <!-- Inner content -->
      <div class="content-inner">
        <router-view></router-view>
      </div>
      <!-- /inner content -->
    </div>
    <!-- /main content -->
  </div>
  <!-- /page content -->

  <!-- Notifications -->
  <div class="offcanvas offcanvas-end" tabindex="-1" id="notifications">
    <div class="offcanvas-header py-0">
      <h5 class="offcanvas-title py-3">Activity</h5>
      <button
        type="button"
        class="btn btn-light btn-sm btn-icon border-transparent rounded-pill"
        data-bs-dismiss="offcanvas"
      >
        <i class="ph-x"></i>
      </button>
    </div>

    <div class="offcanvas-body p-0">
      <div class="bg-light fw-medium py-2 px-3">New notifications</div>
      <div class="p-3">
        <div class="d-flex align-items-start">
          <div class="me-3">
            <div class="bg-warning bg-opacity-10 text-warning rounded-pill">
              <i class="ph-warning p-2"></i>
            </div>
          </div>
          <div class="flex-1">
            Subscription <a href="#">#466573</a> from 10.12.2021 has been
            cancelled. Refund case <a href="#">#4492</a>
            created
            <div class="fs-sm text-muted mt-1">4 hours ago</div>
          </div>
        </div>
      </div>

      <div class="bg-light fw-medium py-2 px-3">Older notifications</div>
      <div class="p-3">
        <div class="d-flex align-items-start mb-3">
          <a href="#" class="status-indicator-container me-3">
            <img
              src="/assets/images/logo_icon.png"
              class="w-40px h-40px rounded-pill"
              alt=""
            />
            <span class="status-indicator bg-danger"></span>
          </a>
          <div class="flex-fill">
            <a href="#" class="fw-semibold">Christine</a> commented on your
            community <a href="#">post</a> from 10.12.2021

            <div class="fs-sm text-muted mt-1">2 days ago</div>
          </div>
        </div>

        <div class="d-flex align-items-start mb-3">
          <div class="me-3">
            <div class="bg-primary bg-opacity-10 text-primary rounded-pill">
              <i class="ph-users-four p-2"></i>
            </div>
          </div>
          <div class="flex-fill">
            <span class="fw-semibold">HR department</span> requested you to
            complete internal survey by Friday

            <div class="fs-sm text-muted mt-1">3 days ago</div>
          </div>
        </div>

        <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /notifications -->
</template>

<script setup>
import { onMounted } from "vue";
import { useAuthStore } from "@/stores";
import { initializeModule } from "@/helpers";
import { API_URL } from "@/const";
import Sidebar from "@/components/Sidebar.vue";

const authStore = useAuthStore();
onMounted(() => {
  initializeModule();
});
</script>
