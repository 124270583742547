<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">Email Template</h6>
        <div class="d-inline-flex ms-auto">
          <router-link to="/email-templates" class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Subject:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Subject"
                v-model="emailTemplate.subject"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Body:</label>
            <div class="col-lg-9">
              <ckeditor
                class="form-control"
                :editor="editorBody"
                v-model="emailTemplate.body"
                :config="editorBodyConfig"
              ></ckeditor>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Code:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Code"
                v-model="emailTemplate.code"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Description:</label>
            <div class="col-lg-9">
              <textarea
                rows="3"
                cols="3"
                class="form-control"
                maxlength="511"
                placeholder="Description"
                v-model="emailTemplate.description"
              ></textarea>
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Status"
                class="form-control status"
              >
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-end" v-if="emailTemplate.id || !id">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              Save <i class="ph-paper-plane-tilt ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, useEmailTemplateStore } from "@/stores";

import { alert, select, MyUploadAdapter } from "@/helpers";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

document.title = "Email Template";

const route = useRoute();
const id = route.params.id;
const authStore = useAuthStore();
const emailTemplateStore = useEmailTemplateStore();
const { emailTemplate, statuses } = storeToRefs(emailTemplateStore);
emailTemplate.value = {};

const editorBody = ref(ClassicEditor);
const editorBodyConfig = ref({
  extraPlugins: [
    function (editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
    },
  ],
});

const result = authStore.getPermission("email_template");
if ((result?.update !== true && id) || (result?.create !== true && !id)) {
  router.push("/login");
}

async function init() {
  select.init("status", (item) => {
    emailTemplate.value.status = parseInt(item.id);
  });
  if (id) {
    await emailTemplateStore.getById(id);
    select.setValue("status", emailTemplate.value.status);
  }
}

async function onSubmit() {
  if (!emailTemplate.value.subject) {
    alert("Please enter Subject");
    return;
  }
  if (!emailTemplate.value.body) {
    alert("Please enter Body");
    return;
  }
  if (!emailTemplate.value.code) {
    alert("Please enter Code");
    return;
  }
  // if (!emailTemplate.value.description) {
  //   alert("Please enter Description");
  //   return;
  // }
  // if (!emailTemplate.value.status) {
  //   alert("Please enter Status");
  //   return;
  // }

  const result = await emailTemplateStore.submit();
  if (result) {
    // router.push("/email-templates");
    router.back();
  }
}

onMounted(() => init());
</script>
